<template>
  <div class="list-area">
    <el-row :gutter="6">
      <el-col :span="6">
        <el-row type="flex" align="middle">
          <span style="white-space: nowrap"> 日期：</span>
          <el-date-picker
            size="small"
            type="daterange"
            range-separator="至"
            v-model="searchData.date"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-input
          size="small"
          placeholder="请输入搜索内容"
          v-model.trim="serachKeyword"
          class="input-with-select"
        >
          <el-select
            size="small"
            slot="prepend"
            v-model="serachKeywordCode"
            placeholder="请选择类型"
            style="width: 120px"
          >
            <el-option label="应用名称" value="apkname"></el-option>
            <el-option label="应用包名" value="pkgname"></el-option>
          </el-select>
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="searchData.country"
          placeholder="国家/地区"
          clearable
          filterable
        >
          <el-option
            v-for="item in countryList"
            :key="item.countryCode"
            :label="item.countryName"
            :value="item.countryCode"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-form :inline="true" label-suffix=":">
      <el-form-item label="客户号" class="demo-form-inline headLabel">
        <el-select
          v-model="searchData.custorm"
          size="small"
          placeholder="请选择客户号"
          clearable
          filterable
        >
          <el-option
            :label="item"
            :value="item"
            v-for="item in custormList"
            :key="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="渠道号" class="demo-form-inline headLabel">
        <el-select
          v-model="searchData.channel"
          size="small"
          placeholder="请选择渠道号"
          clearable
          filterable
        >
          <el-option
            :label="item"
            :value="item"
            v-for="item in channelList"
            :key="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目号" class="demo-form-inline headLabel">
        <el-select
          v-model="searchData.rofcusbr"
          size="small"
          placeholder="请选择项目号"
          clearable
          filterable
        >
          <el-option
            :label="item"
            :value="item"
            v-for="item in rofcusbrList"
            :key="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getTableList()">
          查询
        </el-button>
        <ExportTable class="ml10" @download="getDownloadList"></ExportTable>
      </el-form-item>
    </el-form>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
    </PageTable>
  </div>
</template>
<script>
import PageTable from "@/components/PageTableSort.vue";
import ExportTable from "@/components/ExportTable.vue";
import { getCategoryList, getDownloadSource } from "./indexServe";
import { cmsCountryAll } from "@/common/js/api";
import { MAXSIZEDOWN } from "@/common/constant/constant";

import moment from "moment";
let end = moment(new Date()).format("YYYY-MM-DD");
let start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
start = moment(start).format("YYYY-MM-DD");

export default {
  name: "terminalReport",
  components: { ExportTable, PageTable },
  created() {
    this.fetchCountryList();
    this.fetchCustomList();
    this.init();
  },
  data() {
    return {
      countryList: [],
      custormList: [], //客户号
      channelList: [], //渠道
      rofcusbrList: [], //项目号

      serachKeyword: "",
      serachKeywordCode: "apkname",

      searchData: {
        date: [start, end],
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "pt",
          label: "日期",
          align: "center",
        },
        {
          key: "apkname",
          label: "应用名称",
          align: "center",
        },
        {
          key: "pkgname",
          label: "包名",
          align: "center",
        },
        {
          key: "countryName",
          label: "国家/地区",
          align: "center",
        },
        {
          key: "custorm",
          label: "客户号",
          align: "center",
        },
        {
          key: "channel",
          label: "渠道号",
          align: "center",
        },
        {
          key: "rofcusbr",
          label: "项目号",
          align: "center",
        },
        {
          key: "showPv",
          label: "曝光",
          align: "center",
        },
        {
          key: "downloadPv",
          label: "下载",
          align: "center",
        },
        {
          key: "downloadPvPercent",
          label: "下载率",
          align: "center",
        },
        {
          key: "installPv",
          label: "安装",
          align: "center",
        },
        {
          key: "installPvPercent",
          label: "安装率",
          align: "center",
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
    };
  },

  methods: {
    async fetchCustomList() {
      const res = await getDownloadSource({
        size: 1000,
        page: 1,
      });

      this.custormList = res.data.custormList;
      this.channelList = res.data.channelList;
      this.rofcusbrList = res.data.rofcusbrList;
    },
    // 获取国家地区列表
    async fetchCountryList() {
      const res = await cmsCountryAll();
      this.countryList = [].concat(res.data);
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.tableData.page = page;
      this.tableData.size = size;
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      this.visible = true;
      if (operationType === "create") {
        this.detailData = {};
      }
    },

    // 后端请求
    async getDownloadList(callback) {
      const res = await this.getTableList({ page: 1, size: MAXSIZEDOWN }, true);
      if (res) {
        const headerDisplay = {};
        this.columns.forEach((item) => {
          const key = item.key || item.slot;
          if (key !== "action") {
            headerDisplay[key] = item.label;
          }
        });
        // 处理导出的数据
        const list = res.list;
        callback({
          fileName: "OOBE报表",
          headerDisplay,
          list,
        });
      }
    },
    // 获取列表
    async getTableList(params, isDownload) {
      const obj = {
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      if (obj.date && obj.date.length > 0) {
        obj.startTime = obj.date[0];
        obj.endTime = obj.date[1];
      }
      if (this.serachKeyword) obj[this.serachKeywordCode] = this.serachKeyword;

      const res = await getCategoryList(obj);
      if (res && res.code === 0) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },

    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
