import request from "@/config/serve.js";
// 查询
export function getCategoryList(params) {
  return request("post", "/oobeReport/list", {
    deleted: false,
    ...params,
  });
}
// 下载来源
export function getDownloadSource(params = {}, loading = false) {
  return request(
    "post",
    "/oobeReport/dropDownList",
    {
      deleted: false,
      ...params,
    },
    loading
  );
}
